// $primary: #a51616;
$primary: #a3238e; /* MAIN COLOR */
$secondary: #f68c1e; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Yesteryear');

h1,h2,h3 {
  font-family: 'Yesteryear', cursive;
}

p {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 22px;
}


.xdebug-error.xe-warning {
display: none;
} 

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 125px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 35px;
		    display: inline-block;
		}

		&:focus {
  			outline: 0;
  		}

	    &:hover, &:focus {
	    	// background: $primary;
	    	// color: lighten($primary, 50%);
	    	color:#005c94;
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		// margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	// border: 1px solid $primary;
	border-radius: $border-radius;
	background: $secondary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background:#fce095;
		// background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $blk;
		
		h2 {
			text-align:center;
		}
	}
 
	input {
		border: none;
		border-bottom: 1px solid #fce095;
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: #005c94;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 30px 0px 20px;
	background: $primary;
	color: $footerLinks;
	p {
		font-size: 15px;
	}

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.btn {
    background-color: $secondary;
    // border-color: white;
    font-size: 15px;
    padding: 1em 2em;
    color: #fafafa;

    &:focus:active {
  		outline: 0;
  		-webkit-box-shadow: none;
    	box-shadow: none;
  	}

	&:hover {
		background:rgba(109, 101, 97, 0.6);
  		color: #fafafa;
	}
}

.padBot {
  padding-bottom: 20px;
}

.logo {
  max-width: 325px;
  width: 100%;
  display: inline-block;

  @media (max-width: 768px) {
    max-width: 225px;
  }

  // @media (max-width: 767px) and (orientation: landscape) {
  //   max-width: 10px;
  // }

  @media (max-width: 767px) {
    max-width: 225px;
  }
}

.banner {
  	// background: url(../img/banner.jpg) no-repeat;
  	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #000000 100%), url(../img/banner.jpg);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #000000 100%), url(../img/banner.jpg);
 
  // height: 900px;
  background-size: cover;
  background-position: 50% 50%;
  padding: 350px 0;
  @media (max-width: 767px) {
    height: auto;
    padding: 100px 0;
  }
  h1 {
    color: white;
    font-size: 95px;
  }
}

.banner2 {

	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #000000 100%), url(../img/banner2.jpg);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #000000 100%), url(../img/banner2.jpg);
  // background: url(../img/banner2.jpg) no-repeat;
  // height: auto;
  padding: 200px 0;
  background-position: 50% 50%;
  background-attachment: fixed;
  background-size: cover;
  @media (max-width: 1024px) {
    background-attachment: initial !important;
    background-position: 60% 50%;
    padding: 50px 0;
  }
  @media (max-width: 500px) {
    background-position: 60% 50%;
  }
  h1,p {
    color: white;
  }
}


.bg {
	background: #a3238e;
	background-position: center center;
	background-attachment: fixed;
	background-size: cover;
	color: $wht;
}

.bg h1 {
	font-size: 56px;
}



hr {
  max-width: 300px;
}


.container-fluid {
  .col-md-4 {
    padding: 0;
  }
}


.pic1 {
  background: url(../img/couple.jpg) no-repeat;
  background-size: cover;
  height: 300px;
  background-position: 50% 50%;
  @media (max-width: 991px) {
    height: 400px;
  }
  @media (max-width: 500px) {
    background-position: 80% 50%;
  }
}

.pic2 {
  background: url(../img/couple2.jpg) no-repeat;
  background-size: cover;
  height: 300px;
  background-position: 50% 50%;
  @media (max-width: 991px) {
    height: 400px;
  }
}

.pic3 {
  background: url(../img/couple3.jpg) no-repeat;
  background-size: cover;
  height: 300px;
  background-position: 50% 50%;
  @media (max-width: 991px) {
    height: 400px;
  }
  @media (max-width: 500px) {
    background-position: 30% 50%;
  }
}

.pic4 {
  background: url(../img/couple1.jpg) no-repeat;
  background-size: cover;
  height: 300px;
  background-position: 50% 50%;
  @media (max-width: 991px) {
    height: 400px;
  }
}


.overlay {
	position: relative;
	overflow: hidden;
	padding: 0;
	margin: 0;
	img {
	width: 100%;
}
	h1, p {
		position: absolute;
	}
	h1 {
	top: 40%;
	color: white;
	opacity: 0;
	 transition: opacity 0.5s ease;;
	 z-index: 2;
	 width: 100%;
	 @media (max-width: 1024px) {
			opacity: 1;
	}
}
&:after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	@media (max-width: 1024px) {
		background: rgba(0, 0, 0, 0.6);
		opacity: 1;
}
}
&:hover {
	h1, p {
		opacity: 1
	}
	&:after {
		opacity: 1;
		background: rgba(0, 0, 0, 0.6);
	}
}
}